*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.c-contact{
    position: relative;
    min-height: 100vh;
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background: url(bgContact.png); */
    background-size: cover;
    flex-wrap: wrap-reverse;
}
.content{
    text-align: center;
    max-width: 800px;
}
.content h1{
    font-size: 30px;
    font-weight: bold;
    color: #fff;
}
.content p{
    font-weight: 300;
    color: #fff;
}
.c-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.contactInfo{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.box{
    position: relative;
    padding: 20px 0;
    display: flex; 
}
.icon{
    min-width: 60px;
    height: 60px;
    background: #ffffff8b;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
    cursor: pointer;
}
.text{
    display: flex;
    margin-left: 20px;
    font-size: 16px;
    color: #fff;
    flex-direction: column;
    font-weight: 300;
    text-align: left;
}
.text h3{
    font-weight: bold;
    color: #cd5ff8;
}
.text p{
    font-weight: 300;
}
.contactForm{
    width: 40%;
    padding: 50px;
    /* background: #fff blur(5px); */
    background: rgba(89, 4, 168, 0.08) ;
    backdrop-filter: blur(10px);
    border-radius: 50px;
}
.contactForm h2{
    font-size: 30px;
    color: white;
    font-weight: 500;
    text-align: center;
}
.inputBox{
    position: relative;
    width: 100%;
    margin-top: 10px;
    /* background-color: transparent; */
}
.inputBox input, textarea{
    width: 100%;
    padding: 5px 0.5rem;
    font-size: 16px;
    margin: 10px 0 ;
    border: 2px solid white;
    border-radius: 10px;
    background-color: transparent;
    color: #fff ;
    caret-color: #cd5ff8;
}
.inputBox span{
    position: absolute;
    left: 0;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    pointer-events: none;
    transition: 0.5s;
    color: #666;
    margin-left: 0.5rem;
}
.inputBox input:focus ~ span,
.inputBox input:valid ~ span,
.inputBox textarea:focus ~ span,
.inputBox textarea:valid ~ span
{
    color: #cd5ff8;
    font-size: 12px;
    transform: translateY(-20px);
}
.inputBox input[type="submit"]{
    width: 100px;
    background: #cd5ff8;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 18px;
}
@media (max-width: 991px){
    .c-contact{
        padding: 50px;
        
    }
    .container{
        flex-direction: column;
    }
    .contactInfo{
        margin-bottom: 40px;
    }
    .contactInfo, .contactForm{
        width: 100%;
    }
}
@media (max-width: 600px){
    .c-contact{
        flex-direction: column-reverse;
    }
}